<template>
<div class="restaurant-contract-form">
    <h4>Update Contract: {{ restaurantname_header }}</h4>
    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field contract-input-bar">
            <label>Contract List:</label>
            <form @submit.prevent="contractform_type == 'add' ? addRestaurantContract() : updateRestaurantContract()">
                <InputText id="contract_name" v-model="rest_contract_form.contract_name" type="text" placeholder="Contract name" :class="{'p-invalid':v_contractform$.contract_name.$error}"/>
                <Button label="Save" v-if="contractform_type == 'add'" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                <span v-if="contractform_type == 'update'">
                    <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                    <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelContractUpdate()" />
                </span>
            </form>
        </div>

        <DataTable
            :value="restContractList"
            responsiveLayout="scroll"
            scrollHeight="245px"
            selectionMode="single"
            v-model:selection="selectedRestaurantContract"
            @rowSelect="onRestContractRowSelect"
            dataKey="id"
        >
            <Column field="id" header="ID" :style="{'min-width':'60px'}"></Column>
            <Column field="contract_name" header="Contract name"></Column>

            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editRestaurantContract(slotProps.data)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteRestaurantContract(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field contract-input-bar">
            <label>Contract Validity:</label>
            <form @submit.prevent="contractperiodform_type == 'add' ? addRestaurantContractPeriod() : updateRestaurantContractPeriod()">
                <div class="p-fluid p-grid p-field">
                    <label class="p-col" for="valid_from">From:</label>
                    <div class="p-col">
                        <Calendar id="navigators" style="width: 18rem; height: 2.2rem" v-model="rest_contractperiod_form.valid_from" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':v_contractperiod$.valid_from.$error || isDateValid.isInvalid}"/>
                        <small v-if="v_contractperiod$.valid_from.$error" class="p-error">{{v_contractperiod$.valid_from.required.$message}}</small>
                        <small v-if="isDateValid.isInvalid" class="p-error">{{isDateValid.message}}</small>
                    </div>
                    <label class="p-col" for="valid_to" >To:</label>
                    <div class="p-col">
                        <Calendar id="navigators" style="width: 18rem; height: 2.2rem" v-model="rest_contractperiod_form.valid_to" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':v_contractperiod$.valid_to.$error || isDateValid.isInvalid}"/>
                        <small v-if="v_contractperiod$.valid_to.$error" class="p-error">{{v_contractperiod$.valid_to.required.$message}}</small>
                        <small v-if="isDateValid.isInvalid" class="p-error">{{isDateValid.message}}</small>
                    </div>

                    <div class="p-col">
                        <Button label="Save" v-if="contractperiodform_type == 'add'" type="submit" style="width: 5rem" class="p-button-sm p-button-success" icon="pi pi-save" :disabled="selectedRestaurantContract.id == 0"/>
                        <span v-if="contractperiodform_type == 'update'">
                            <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                            <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelContractPeriodUpdate()" />
                        </span>
                    </div>
                </div>
            </form>
        </div>
        <div class="p-grid p-field">
            <div class="p-col">
                <label class="p-mr-5">Contract:</label>
                <span style="font-weight: 500">{{selectedRestaurantContract.contract_name}}</span>
            </div>
        </div>
        <DataTable
            id="Contract-Period"
            :value="restContractPeriodList"
            responsiveLayout="scroll"
            scrollHeight="245px"
            selectionMode="single"
            v-model:selection="selectedRestContractPeriod"
            @rowSelect="onRestContractPeriodRowSelect"
            dataKey="id"
        >
            <Column field="rest_contract_id" header="Contract ID" :style="{'min-width':'30px'}"></Column>
            <Column field="valid_from" header="From" :style="{'min-width':'60px'}"></Column>
            <Column field="valid_to" header="To" :style="{'min-width':'60px'}"></Column>

            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editRestContractPeriod(slotProps.data)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteRestContractPeriod(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>

    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field contract-input-bar">
            <label class="p-mr-4 p-mb-3">Menu Price:</label>
            <span style="font-weight: 500">
                {{ contractRateTitle }}
            </span>
            <form @submit.prevent="menurateform_type == 'add' ? addMenuPrice() : updateMenuPrice()">
                <div class="p-fluid p-grid p-field">
                    <div class="p-col-fixed">
                        <label for="menu_name">Menu name:</label>
                    </div>
                    <div class="p-col">
                        <!-- <InputText id="menu_name" style="width: 25rem" v-model="menurate_form.menu_name" type="text" :class="{'p-invalid':v_menurate$.menu_name.$error}"/> -->
                        <Dropdown v-model="selectedMenu" style="width: 18rem" @change="onMenuDropdownChanged()" @input="onMenuDropdownInput()" :editable="true" :options="menuList" optionLabel="menu_name" :class="{'p-invalid':v_menurate$.menu_name.$error}">
                            <template #option="slotProps">
                                <div style="float: left">
                                    {{slotProps.option.menu_name}}
                                </div>
                                <span style="float: right; color: red; margin-top: 3px" class="pi pi-times" @click="confirmDeleteMenuName(slotProps.option.id, slotProps.option.menu_name)"></span>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="p-col">
                        <label for="lunch_price">Lunch:</label>
                    </div>
                    <div class="p-col">
                        <InputNumber id="lunch_price" style="width: 7rem" v-model="menurate_form.lunch_price" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="{'p-invalid':v_menurate$.lunch_price.$error}"/>
                        <small v-if="v_menurate$.lunch_price.$error" class="p-error">Price must be > 0</small>
                    </div>
                    <div class="p-col">
                        <label for="dinner_price">Dinner:</label>
                    </div>
                    <div class="p-col">
                        <InputNumber id="dinner_price" style="width: 7rem" v-model="menurate_form.dinner_price" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="{'p-invalid':v_menurate$.dinner_price.$error}"/>
                        <small v-if="v_menurate$.dinner_price.$error" class="p-error">Price must be > 0</small>
                    </div>
                    <div class="p-col-fixed">
                        <Button label="Save" v-if="menurateform_type == 'add'" type="submit" style="width: 5rem" class="p-button-sm p-button-success" icon="pi pi-save" :disabled="!menuPriceButton" />
                        <span v-if="menurateform_type == 'update'">
                            <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                            <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelMenuPriceUpdate()" />
                        </span>
                    </div>
                </div>
            </form>
        </div>

        <DataTable
            id="Menu-Price-List"
            :value="menuPriceList"
            responsiveLayout="scroll"
            scrollHeight="245px"
            selectionMode="single"
            v-model:selection="selectedMenuPrice"
            @rowSelect="onMenuPriceSelect"
            dataKey="id"
        >
            <Column field="id" header="ID" :style="{'min-width':'60px'}"></Column>
            <Column field="menu_name" header="Menu name"></Column>
            <Column field="lunch_price" header="Lunch"></Column>
            <Column field="dinner_price" header="Dinner"></Column>

            <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editMenuPrice(slotProps.data)"/>
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteMenuPrice(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
        <div class="p-grid p-mt-2">
            <div class="p-col" style="text-align: right">
                <span style="font-weight: 500">Menu prices are in USD</span>
            </div>
        </div>
        <hr style="border: 0; border-top: 1px solid var(--surface-d);"/>
        <div class="p-field">
            <label class="p-mr-4">Children Policy:</label>
            <div class="p-grid p-fluid">
                <div class="p-col">
                    <InputText id="children_policy" v-model="contract_policy.desc" @input="onChildrenPolicyInput()" type="text" />
                </div>
                <div class="p-col-fixed">
                    <Button icon="pi pi-save" class="small-datatable-button p-button-success p-button-rounded p-mr-1" @click="saveChildrenPolicy()" :disabled="!menuPriceButton" />
                </div>
            </div>
        </div>
    </div>
    
    <div class="card form-view" style="margin-bottom: 1rem">
        <div class="p-field contract-input-bar">
            <label class="p-mr-4 p-mb-3">Menu Dishes:</label>
            <Editor v-model="menuDishes.dish_desc" editorStyle="height: 280px"> </Editor>
        </div>
        <Button label="Save Dishes" style="width: 8rem" class="p-button-sm p-button-success" icon="pi pi-save" @click="saveMenuDishes()" :disabled="!isSaveDishButton" />
    </div>
    <ConfirmDialog></ConfirmDialog>
</div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from 'vue-router';
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import dayjs from 'dayjs';

import RestaurantService from '../service/RestaurantService'

export default {    
    setup() {
        const confirm = useConfirm();
        const route = useRoute();
        const toast = useToast();

        const restaurant_id = route.query.restaurantid
        let restaurantname_header = ref('')

        const restaurantService = ref(new RestaurantService())

        onMounted(() => {
            getRestaurantName()
            getRestaurantContract()
        })

        const getRestaurantName = () => {
            restaurantService.value.getRestaurantName(restaurant_id).then(data =>  {
                if(data.errorResponse)  {
                    restaurantname_header.value = data.errorResponse
                } else {
                    restaurantname_header.value = data
                }
            })
        }

        //==============CONTRACT LIST==============
        let restContractList = ref([])
        let selectedRestaurantContract = ref({id: 0, contract_name: ''})
        let rest_contract_form = reactive({})
        let contractform_type = ref('add')

        const initContractForm = () => {
            const contractForm = {
                contract_name: '',
                restaurant_id: 0
            }

            Object.assign(rest_contract_form, contractForm)
        }

        const getRestaurantContract = () => {
            restaurantService.value.getContract(restaurant_id).then((data) => {
                restContractList.value = data
            })
        }

        const onRestContractRowSelect = () => {
            cancelContractUpdate()
            getRestaurantContractPeriod(selectedRestaurantContract.value.id)
            
            menuList.value.length = 0
            menuPriceList.value.length = 0

            cancelContractPeriodUpdate()
            selectedRestContractPeriod.value = {}

            cancelMenuPriceUpdate()
            selectedMenuPrice.value = {}
            isSaveDishButton.value = false
            menuPriceButton.value = false
            menuDishes.dish_desc = ''
        }

        const addRestaurantContract = () => {
            if(validateContractForm()) {
                rest_contract_form.restaurant_id = restaurant_id
                restaurantService.value.addRestaurantContract(rest_contract_form).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            toast.add({severity:'warn', summary: 'Add Contract Error ', detail: data.message, life: 5000}); 
                        } else {
                            getRestaurantContract()
                            initContractForm()
                            v_contractform$.value.$reset()
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Add Contract Error ' + data.status, detail: data.errorResponse, life: 5000}); 
                    }
                })
            }
        }

        const updateRestaurantContract = () => {
            if(validateContractForm()) {
                const contract_id = selectedRestaurantContract.value.id
                if(selectedRestaurantContract.value.contract_name != rest_contract_form.contract_name) {
                    restaurantService.value.updateRestaurantContract(contract_id, {contract_name: rest_contract_form.contract_name, restaurant_id: restaurant_id}).then(data => {
                        if(!data.errorResponse) {
                            if(data.status == 400) {
                                toast.add({severity:'warn', summary: 'Update Contract Error ', detail: data.message, life: 5000}); 
                            } else {
                                getRestaurantContract()
                                initContractForm()
                                v_contractform$.value.$reset()
                                contractform_type.value = 'add'
                            }
                        } else {
                            toast.add({severity:'warn', summary: 'Update Contract Error ' + data.status, detail: data.errorResponse, life: 5000}); 
                        }
                    })
                }
            }
        }

        const confirmDeleteRestaurantContract = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.contract_name +'" from this restaurant?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const contract_id = data.id
                    restaurantService.value.deleteRestaurantContract(contract_id).then((data) => {
                        if(!data.errorResponse){
                            getRestaurantContract()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                        }
                    })
                }
            });
        }

        const editRestaurantContract = (data) => {
            contractform_type.value = 'update'
            selectedRestaurantContract.value = data
            rest_contract_form.contract_name = data.contract_name
        }

        const cancelContractUpdate = () =>{
            contractform_type.value = 'add'
            v_contractform$.value.$reset()
            initContractForm()
        }

        //Form Validation
        const restcontractform_rules = computed(() => {
            return {
                contract_name: { required },
            }            
        })
        const v_contractform$ = useVuelidate(restcontractform_rules, rest_contract_form)
        const validateContractForm = () => {
            v_contractform$.value.$validate();
            if(!v_contractform$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============CONTRACT PERIOD==============
        let restContractPeriodList = ref([])
        let selectedRestContractPeriod= ref({})        
        let contractperiodform_type = ref('add')
        let rest_contractperiod_form = reactive({})
        let isDateValid = ref({isInvalid: false, message: ''})
        let contractRateTitle = ref()

        const initContractPeriodForm = () => {
            const contractPeriodForm = {
                valid_from: '',
                valid_to: '',
                rest_contract_id: 0
            }            

            Object.assign(rest_contractperiod_form, contractPeriodForm)
        }

        const onRestContractPeriodRowSelect = () => {
            cancelContractPeriodUpdate()

            contractRateTitle.value = selectedRestaurantContract.value.contract_name + ' [' + selectedRestContractPeriod.value.valid_from + ' - ' + selectedRestContractPeriod.value.valid_to + ']'
            setMenuListDropdown()
            getMenuPrice()
            getContractPolicy('Children Policy')
            selectedMenuPrice.value = {}
            isSaveDishButton.value = false
            menuPriceButton.value = true
            menuDishes.dish_desc = ''
            contract_policy.value = []
        }

        function getRestaurantContractPeriod(contract_id) {
            restaurantService.value.getRestaurantContractPeriod(contract_id).then(data => {
                if(!data.errorResponse) {
                    restContractPeriodList.value = data
                } else {
                    toast.add({severity:'warn', summary: 'Getting Contract Period Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        const addRestaurantContractPeriod = () => {
            if(selectedRestaurantContract.value.id != 0) {
                if(validateForm() && validateContractPeriodDate()) {
                    const validFrom = dayjs(rest_contractperiod_form.valid_from).format('DD-MMM-YYYY')
                    const validTo =  dayjs(rest_contractperiod_form.valid_to).format('DD-MMM-YYYY')

                    rest_contractperiod_form.valid_from = validFrom
                    rest_contractperiod_form.valid_to = validTo
                    rest_contractperiod_form.rest_contract_id = selectedRestaurantContract.value.id

                    restaurantService.value.addRestContractPeriod(rest_contractperiod_form).then(data => {
                        if(!data.errorResponse) {
                            initContractPeriodForm()
                            getRestaurantContractPeriod(selectedRestaurantContract.value.id)
                            v_contractperiod$.value.$reset() //Reset validations
                        } else {
                            toast.add({severity:'warn', summary: 'Add Contract Period Error '+data.status, detail: data.errorResponse, life: 6000}); 
                        }
                    })
                }
            }
        }

        const updateRestaurantContractPeriod = () => {
            if(selectedRestaurantContract.value.id != 0) {
                if(validateForm() && validateContractPeriodDate()) {
                    const validFrom = dayjs(rest_contractperiod_form.valid_from).format('DD-MMM-YYYY')
                    const validTo =  dayjs(rest_contractperiod_form.valid_to).format('DD-MMM-YYYY')

                    const id = selectedRestContractPeriod.value.id
                    rest_contractperiod_form.valid_from = validFrom
                    rest_contractperiod_form.valid_to = validTo
                    
                    restaurantService.value.updateRestContractPeriod(id, rest_contractperiod_form).then(data => {
                        if(!data.errorResponse) {
                            initContractPeriodForm()
                            getRestaurantContractPeriod(selectedRestaurantContract.value.id)
                            contractperiodform_type.value = 'add'
                            v_contractperiod$.value.$reset() //Reset validations
                        } else {
                            toast.add({severity:'warn', summary: 'Update Contract Period Error '+data.status, detail: data.errorResponse, life: 6000}); 
                        }
                    })   
                }
            }
        }

        const confirmDeleteRestContractPeriod = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this restaurant contract period?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const id = data.id
                    restaurantService.value.deleteRestContractPeriod(id).then((data) => {
                        if(!data.errorResponse){
                            getRestaurantContractPeriod(selectedRestaurantContract.value.id)
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error '+data.status, detail: data.errorResponse, life: 6000});        
                        }
                    })
                }
            });
        }

        const editRestContractPeriod = (data) => {
            contractperiodform_type.value = 'update'
            selectedRestContractPeriod.value = data

            rest_contractperiod_form.valid_from = data.valid_from
            rest_contractperiod_form.valid_to = data.valid_to
        }

        const cancelContractPeriodUpdate = () =>{
            contractperiodform_type.value = 'add'
            initContractPeriodForm()
            v_contractperiod$.value.$reset()
            isDateValid.value = {isInvalid: false, message: ''}
        }

        const validateContractPeriodDate = () => {
            const validfrom = new Date(rest_contractperiod_form.valid_from)
            const validto = new Date(rest_contractperiod_form.valid_to)
            if(validfrom > validto) {
                isDateValid.value = {isInvalid: true, message: 'Selected date is invalid.'}
                return false
            } else {
                isDateValid.value = {isInvalid: false, message: ''}
                return true
            }
        }

        //Form Validations
        const restcontractperiod_rules = computed(() => {
            return {
                valid_from: { required },
                valid_to: { required },
            }            
        })
        const v_contractperiod$ = useVuelidate(restcontractperiod_rules, rest_contractperiod_form)
        const validateForm = () => {
            v_contractperiod$.value.$validate();
            if(!v_contractperiod$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============MENU RATE==============
        let menurate_form = reactive({})
        let menurateform_type = ref('add')
        let menuPriceList = ref([])
        let selectedMenuPrice = ref()
        let menuList = ref([])
        let selectedMenu = ref()
        let selectedMenu_old = reactive({}) //Use in update form/function
        let menuPriceButton = ref(false)

        const initMenuRateForm = () => {
            const menuRateForm = {
                menu_name: '',
                lunch_price: null,
                dinner_price: null,
            }

            Object.assign(menurate_form, menuRateForm)
        }

        const onMenuPriceSelect = () => {
            cancelMenuPriceUpdate()
            menuDishesTitle.value = selectedMenuPrice.value.menu_name
            getMenuDish()
            isSaveDishButton.value = true
        }

        function getMenuPrice () {
            const periodid = selectedRestContractPeriod.value.id
            
            restaurantService.value.getMenuPrice(periodid).then((data) => {
                if(data.length > 0) {
                    menuPriceList.value = data
                } else {
                    menuPriceList.value.length = 0
                }
            })
        }

        const setMenuListDropdown = () => {
            restaurantService.value.getMenu(restaurant_id).then(data => {
                if(!data.errorResponse) {
                    menuList.value = data
                }
            })
        }

        const onMenuDropdownChanged = () => {
            if(selectedMenu.value.menu_name) {
                menurate_form.menu_name = selectedMenu.value.menu_name
                Object.assign(menurate_form, {menu_id: selectedMenu.value.id })
            }
        }

        const onMenuDropdownInput = () => {
            menurate_form.menu_name = selectedMenu.value
            Object.assign(menurate_form, {menu_id: '' })
        }

        const addMenuPrice = () => {
            if(validateMenuRateForm()) {
                Object.assign(menurate_form, {
                    restaurant_id: parseInt(restaurant_id),
                    restcontract_period_id: selectedRestContractPeriod.value.id
                })

                const findMenuName = menuList.value.find(item => item.menu_name == menurate_form.menu_name)
                if(findMenuName) {
                    menurate_form.menu_id = findMenuName.id
                }
                
                restaurantService.value.addMenuPrice(menurate_form).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            toast.add({severity:'warn', summary: 'Add Menu Error', detail: data.message, life: 5000}); 
                        } else {
                            toast.add({severity:'success', summary: 'Menu Price', detail: 'Menu price successfully saved.', life: 4000}); 
                            getMenuPrice()
                            setMenuListDropdown()
                            initMenuRateForm()
                            selectedMenu.value = ''
                            v_menurate$.value.$reset()
                        }
                    }
                })
            }            
        }

        const updateMenuPrice = () => {
            if(validateMenuRateForm()) {
                const menu_price_id = selectedMenuPrice.value.id;
                let update_data = {}

                if(selectedMenu_old.menu_name !== menurate_form.menu_name) {
                    //CHECK Menu Name Dropdown INPUT IF EXIST IN DATATABLE
                    const checkExist = menuPriceList.value.find(item => item.menu_name == menurate_form.menu_name)
                    if(checkExist) {
                        return toast.add({severity:'warn', summary: 'Menu Exist', detail: 'This menu is already exist in the datatable.', life: 5000}); 
                    } else {
                        //CHECK Menu Name Dropdown INPUT IF EXIST IN menuList array
                        const findMenuName = menuList.value.find(item => item.menu_name == menurate_form.menu_name)
                        if(findMenuName) {
                            //FOR UPDATING DATA IN restaurant_menu_price TABLE
                            update_data = {
                                lunch_price: menurate_form.lunch_price,
                                dinner_price: menurate_form.dinner_price,
                                restcontract_period_id: selectedRestContractPeriod.value.id,
                                restmenu_id: findMenuName.id
                            }
                        } else {
                            //FOR UPDATING DATA IN restaurant_menu_price and restaurant_menu TABLE
                            //IF NOT FOUND, THEN CREATE NEW MENU 
                            update_data = {
                                menu_id: selectedMenu_old.id,
                                menu_name: menurate_form.menu_name,
                                lunch_price: menurate_form.lunch_price,
                                dinner_price: menurate_form.dinner_price,
                                restaurant_id: parseInt(restaurant_id),
                                restcontract_period_id: selectedRestContractPeriod.value.id,
                            }
                        }
                    }
                } else {
                    update_data = {
                        lunch_price: menurate_form.lunch_price,
                        dinner_price: menurate_form.dinner_price,
                        restcontract_period_id: selectedRestContractPeriod.value.id,
                        restmenu_id: selectedMenu.value.id
                    }
                }
                
                restaurantService.value.updateMenuPrice(menu_price_id, update_data).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            toast.add({severity:'warn', summary: 'Restaurant Menu Exist', detail: data.message, life: 5000}); 
                        } else {
                            getMenuPrice()
                            setMenuListDropdown()
                            cancelMenuPriceUpdate()
                            toast.add({severity:'success', summary: 'Update Menu', detail: 'Menu price successfully updated.', life: 4000}); 
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Update Menu Error '+data.status, detail: data.message, life: 5000}); 
                    }
                })
            }
        }

        const editMenuPrice = (data) => {
            menurateform_type.value = 'update'
            selectedMenu_old = {id: data.menu_id, menu_name: data.menu_name}

            selectedMenuPrice.value = data  //To select menu price row in datatable
            selectedMenu.value = {id: data.menu_id, menu_name: data.menu_name}
            menurate_form.menu_name = data.menu_name
            menurate_form.lunch_price = parseFloat(data.lunch_price)
            menurate_form.dinner_price = parseFloat(data.dinner_price)
        }
        
        const cancelMenuPriceUpdate = () => {
            menurateform_type.value = 'add'
            initMenuRateForm()
            selectedMenu.value = ''
            v_menurate$.value.$reset()
        }

        const confirmDeleteMenuPrice = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this restaurant menu price?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const menu_priceID = data.id
                    if(menu_priceID) {
                        restaurantService.value.deleteMenuPrice(menu_priceID).then(data => {
                            if(!data.errorResponse){
                                getMenuPrice()
                            } else {
                                toast.add({severity:'warn', summary: 'Menu Price Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    }
                }
            });
        }

        const confirmDeleteMenuName = (menuID, menuName) =>  {
            confirm.require({
                message: 'Are you sure you want to delete this restaurant menu "'+ menuName +'"?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    if(menuID) {
                        restaurantService.value.deleteMenuName(menuID).then(data => {
                            if(!data.errorResponse){
                                getMenuPrice()
                                setMenuListDropdown()
                                selectedMenu.value = ''
                            } else {
                                toast.add({severity:'error', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    }
                }
            });
        }

        let contract_policy = ref([])
        let childrenPolicy = reactive({id: '', policy_type:'', desc: '', restcontract_period_id: 0})

        const getContractPolicy = (policyType) => {
            const periodid = selectedRestContractPeriod.value.id

            restaurantService.value.getContractPolicy(policyType, periodid).then(data => {
                if(!data.errorResponse) {
                    if(data.length > 0)  {
                        contract_policy.value = data[0]
                    }  else {
                        contract_policy.value = {id: '', policy_type:'', desc: '', restcontract_period_id: 0}
                    }
                }
            })
        }

        const onChildrenPolicyInput = () => {
            childrenPolicy.desc = contract_policy.value.desc
        }

        const saveChildrenPolicy = () => {
            if(childrenPolicy.desc != '') {
                childrenPolicy.id = contract_policy.value.id
                childrenPolicy.policy_type = 'Children Policy'
                childrenPolicy.restcontract_period_id = selectedRestContractPeriod.value.id

                restaurantService.value.saveChildrenPolicy(childrenPolicy).then(data => {
                    if(!data.errorResponse)  {
                        toast.add({severity:'success', summary: 'Menu Price', detail: 'Menu price successfully saved.', life: 4000}); 
                        getContractPolicy('Children Policy')
                    } else {
                        toast.add({severity:'warn', summary: 'Save Children Policy Error '+data.status, detail: data.message, life: 5000}); 
                    }
                })
            }
        }

        //Form Validations
        const menurate_rules = computed(() => {
            return {
                menu_name: { required },
                lunch_price: { required, minValue: minValue(1) },
                dinner_price: { required, minValue: minValue(1) },
            }            
        })
        const v_menurate$ = useVuelidate(menurate_rules, menurate_form)
        const validateMenuRateForm = () => {
            v_menurate$.value.$validate();
            if(!v_menurate$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============MENU DISHES==============
        const menuDishesTitle = ref('')
        let menuDishes = reactive({})
        let isSaveDishButton = ref(false)

        // const saveDishInputToArray = (InputDishArray, DishDataObject, DataIndex, DishType) => {
        //     const index = InputDishArray.value.findIndex(val => val.idx == DataIndex)
        //     if(index == -1) {
        //         InputDishArray.value = [...InputDishArray.value, Object.assign(DishDataObject.value[DataIndex], {dish_type: DishType, menu_id: selectedMenuPrice.value.menu_id, idx: DataIndex})]
        //     } 
        // }

        const initMenuDishes = () => {
            const menu_dish = {
                id: '',
                dish_desc: '<p><strong>Starter:</strong></p><p><br></p><p><strong>Soup:</strong></p><p><br></p><p><strong>Main Course:</strong></p><p><br></p><p><strong>Dessert:</strong></p><p><br></p><br>',
                menu_id: 0,
            }

            Object.assign(menuDishes, menu_dish)
        }

        const getMenuDish = () => {
            const menuID = selectedMenuPrice.value.menu_id

            restaurantService.value.getMenuDish(menuID).then(data => {
                if(!data.errorResponse) {
                    if(data.length == 0) {
                        initMenuDishes()
                    } else {
                         Object.assign(menuDishes, data[0])
                    }
                } else {
                    toast.add({severity:'warn', summary: 'Getting Menu Dish Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        const saveMenuDishes = () => {             
            menuDishes.menu_id = selectedMenuPrice.value.menu_id

            restaurantService.value.addMenuDish(menuDishes).then(data => {
                if(!data.errorResponse) {
                    getMenuDish()
                    toast.add({severity:'success', summary: 'Menu Dish', detail: 'Menu dishes successfully saved.', life: 4000});
                } else {
                    toast.add({severity:'warn', summary: 'Save Dish Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        return {
            restaurantname_header,

            restContractList,
            selectedRestaurantContract,
            onRestContractRowSelect,
            
            v_contractform$,
            rest_contract_form,
            contractform_type,
            addRestaurantContract,
            updateRestaurantContract,
            editRestaurantContract,
            cancelContractUpdate,
            confirmDeleteRestaurantContract,

            v_contractperiod$,
            restContractPeriodList,
            selectedRestContractPeriod,
            contractperiodform_type,
            rest_contractperiod_form,
            isDateValid,
            onRestContractPeriodRowSelect,
            addRestaurantContractPeriod,
            updateRestaurantContractPeriod,
            cancelContractPeriodUpdate,
            editRestContractPeriod,
            confirmDeleteRestContractPeriod,
            contractRateTitle,

            v_menurate$,
            menurate_form,
            menurateform_type,
            menuPriceList,
            selectedMenuPrice,
            onMenuPriceSelect,
            menuList,
            selectedMenu,
            onMenuDropdownChanged,
            onMenuDropdownInput,
            addMenuPrice,
            updateMenuPrice,
            editMenuPrice,
            cancelMenuPriceUpdate,
            confirmDeleteMenuPrice,
            confirmDeleteMenuName,
            contract_policy,
            onChildrenPolicyInput,
            saveChildrenPolicy,
            menuPriceButton,

            menuDishesTitle,
            menuDishes,
            saveMenuDishes,
            isSaveDishButton,
        }
    },
}
</script>

<style lang="scss" scoped>
.restaurant-contract-form .card {
    padding: 1rem 1rem;
}
.contract-input-bar .p-inputtext{
    width: 49rem;
    margin-right: .8rem;
}
</style>